import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.admin.report.detail');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';
import moment from "moment";

@Component({
    selector: 'wiz-portal-microbeadmin-admin-report-detail',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.admin.report.detail/view.scss */
.container {
  max-width: 1187px;
  height: 85%;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  opacity: 0.9;
}

.table-card {
  border: none;
  border-radius: 0;
  max-width: 1127px;
}
.table-card tr {
  font: normal normal bold 14px/18px SUIT;
}

.dropdown .dropdown-toggle {
  border-radius: 7px;
  color: #707070;
  border: 1px solid #C7C7C7;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown .dropdown-menu {
  max-width: 91px;
  min-width: 80%;
}
.dropdown .dropdown-item {
  min-width: 0;
}

.disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: default !important;
}

.btn-files {
  width: 24px;
  height: 24px;
  background: #ECF5FF;
  border-radius: 3px;
  border: none;
  justify-content: center;
}
.btn-files img {
  width: 20px;
  max-width: none;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.d-block {
  height: 241px;
}

.file-list {
  display: flex;
  justify-content: flex-end;
}

li {
  background: #ECF5FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  width: 511px;
  height: 32px;
  display: flex;
  align-items: center;
}
li a {
  padding-left: 0.5rem;
  color: #5263FF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  font: normal normal normal 16px/20px SUIT;
  text-decoration: underline;
}
li .btn-remove-file {
  height: 30px !important;
  width: 30px !important;
  border: none;
  background-color: #ECF5FF;
  margin-right: 5px;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

#file {
  display: none;
}

.file-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  font: normal normal medium 18px/23px SUIT;
  color: #000000;
}
.file-box .btn-blue {
  border-radius: 10px;
  width: 94px;
  height: 40px;
  font: normal normal normal 16px/21px SUIT;
}

ngx-dropzone {
  width: 500px;
  height: 241px;
  background: #EEF7FF;
  border: 1px dashed #2547F4;
}
ngx-dropzone ngx-dropzone-label {
  font: normal normal medium 18px/23px SUIT !important;
}

.file-card {
  display: flex;
  width: 1122px;
  height: 302px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
}

.form-control {
  width: 100%;
  height: 32px;
  border-radius: 7px;
  font: normal normal bold 18px/24px SUIT;
}

.wiz-form {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  max-width: 1127px;
  margin: auto;
}
.wiz-form .wiz-form-label {
  background: #F4F4F4;
  font: normal normal medium 16px/20px SUIT;
  color: #707070;
}
.wiz-form .form-control {
  width: 477px;
  height: 32px;
  border-radius: 7px;
  font: normal normal normal 14px/18px SUIT;
}

.vertical {
  border: 0.5px solid #808080;
  height: 241px;
  margin-top: auto;
  margin-bottom: auto;
}

.rows {
  padding-bottom: 16rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: 1rem;
  height: 50px;
  width: 100%;
  max-width: 1127px;
  font: normal normal normal 16px/18px SUIT;
}

.row-user {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: 1rem;
  height: 50px;
  display: flex;
  width: 100%;
  max-width: 1127px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}
.row-user .btn-delete {
  width: 83px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  border: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  box-shadow: none;
}
.row-user .btn-delete:hover {
  background: none;
}

.position-absolute {
  display: flex;
  align-items: center;
}

.btn-common.active {
  color: white;
  background-color: #6184D6;
}

td,
th {
  text-align: center;
}

.table-responsive {
  overflow-y: auto;
  border-radius: 0;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  max-width: 77px;
  max-height: 48px;
  padding: 1.5rem;
}
.page-header .btn .btn-blue {
  background: #2547F4;
}
.page-header .btn-cancel {
  background: #EF2B2A !important;
  font: normal normal normal 14px/18px SUIT;
  color: #FFFFFF;
  border: none;
}

.table-last {
  max-width: 1127px;
  margin: 0 auto;
}
.table-last thead {
  text-align: left;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  height: 32px;
}
.table-last thead th {
  font: normal normal bold 14px/18px SUIT;
  background: none;
  vertical-align: middle;
  color: #707070;
}
.table-last tbody tr {
  width: 1440px;
  height: 50px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr td {
  height: 30px;
  padding: 0;
  font: normal normal normal 16px/20px SUIT;
  color: #000000 !important;
}`],
})
export class PortalMicrobeadminAdminReportDetailComponent implements OnInit {
    public search: any = {
        page: 1,
        text: ''
    };
    public uploadedFiles = [];
    public year_list = []
    public pagenation: any = {
        end: -1,
        start: -1
    };
    public item: any = {
        title: '',
        number: '',
        status: '',
        date: moment().format("YYYY"),
        order_institute: '',
        implement_institute: '',
        files: []
    };
    public fd = new FormData();
    public selectedValue: string | null = null;
    public files = [];
    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    @Input() post_id = '';

    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public async load(page = 1) {
        const currentYear = new Date().getFullYear();

        for (let year = currentYear; year >= 2013; year--) {
            this.year_list.push(year.toString());
        }

        if (this.post_id != 'new') {
            const { code, data } = await wiz.call("get", { id: this.post_id });
            this.item = data;
            // this.item.date = moment(this.item.date).format("YYYY");
            console.log(this.item.date, '아이템 데이트')
            this.item.files = JSON.parse(data.files.replace(/'/g, '"'));
        }
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public selectValue(status: string, role: string, index: number): void {
        this.list[index].status = status;
        this.list[index].role = role;
    }
    public async update() {
        if (this.item.title.length == 0) {
            await this.alert('제목을 입력해주세요');
            return
        }

        // const [year, month] = this.item.date.split('-');
        // this.item.date = `${year}-${month}-01`;

        // 기존 파일 리스트를 FormData에 추가 (중복 체크)
        // for (const file of this.item.files) {
        //     if (!this.uploadedFiles.includes(file)) {
        //         this.fd.append('file[]', file); // 기존 파일을 FormData에 추가
        //     }
        // }
        this.item.files = [...new Set([...this.item.files, ...this.uploadedFiles])];
        this.uploadedFiles = [];

        this.fd.append('data', JSON.stringify(this.item))

        let url = wiz.url('update')

        const { code, data } = await this.service.file.upload(url, this.fd);
        if (code == 200) {
            await this.alert('저장되었습니다', '', 'success', '확인');
        }


        this.router.navigateByUrl('/admin/report')
    }

    // public async update() {
    //     if (this.item.title.length == 0) {
    //         await this.alert('제목을 입력해주세요');
    //         return;
    //     }

    //     // 기존 파일 리스트를 FormData에 추가 (중복 체크)
    //     this.item.files = [...new Set([...this.item.files, ...this.uploadedFiles])];
    //     this.uploadedFiles = [];

    //     // 데이터를 폼에 추가
    //     this.fd.append('data', JSON.stringify(this.item));

    //     // 파일을 청크로 나누고 청크 데이터를 폼에 추가
    //     const chunkData = []; // 청크 데이터를 저장할 배열

    //     for (let i = 0; i < this.item.files.length; i++) {
    //         let file = this.item.files[i];

    //         // 100MB 이상인 파일은 청크로 나눠서 업로드
    //         if (file.size > 100 * 1024 * 1024) {  // 100MB
    //             console.log('파일이 100MB 이상이므로 청크로 나눕니다.');
    //             await this.prepareFileChunks(file, chunkData);  // 청크 준비
    //         } else {
    //             // 작은 파일은 그대로 폼에 추가
    //             this.fd.append('file[]', file);
    //         }
    //     }

    //     // 모든 청크 데이터를 폼에 추가
    //     chunkData.forEach(chunk => {
    //         this.fd.append('file[]', chunk.chunk);
    //         this.fd.append('partNumber', chunk.partNumber);  // 청크 번호
    //         this.fd.append('totalParts', chunk.totalParts);  // 총 청크 수
    //         this.fd.append('filename', chunk.filename);  // 원본 파일 이름
    //     });

    //     // 서버로 폼 데이터 전송
    //     let url = wiz.url('update');

    //     const { code, data } = await this.service.file.upload(url, this.fd);
    //     if (code == 200) {
    //         await this.alert('저장되었습니다', '', 'success', '확인');
    //     }

    //     this.router.navigateByUrl('/admin/report');
    // }


    // 파일을 청크 `단위로 나누어 저장할 함수
    public async prepareFileChunks(file, chunkData) {
        const chunkSize = 10 * 1024 * 1024;  // 10MB 청크 크기
        const totalChunks = Math.ceil(file.size / chunkSize);  // 전체 청크 수

        // 각 청크를 순차적으로 나누어 배열에 저장
        for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min((i + 1) * chunkSize, file.size);
            const chunk = file.slice(start, end);  // 파일의 청크를 잘라냄

            chunkData.push({
                chunk: chunk,
                partNumber: i + 1,  // 청크 번호
                totalParts: totalChunks,  // 총 청크 수
                filename: file.name  // 원본 파일 이름
            });

            console.log(`청크 ${i + 1} 준비 완료`);
        }
    }
    public onDropdownClick(event: MouseEvent, id: string) {
        event.stopPropagation();
        this.download(id);
    }

    public async removeFile(index: number, isUploaded: boolean = false) {
        if (isUploaded) {
            this.uploadedFiles.splice(index, 1); // 업로드한 파일 삭제
        } else {
            this.item.files.splice(index, 1); // 저장된 파일 삭제
        }
    }
    public async download() {
        // 아이템을 list에서 찾음
        let files = this.item.files;
        console.log(files, '파일스')
        // 각 파일에 대해 다운로드 URL 생성 및 새 탭에서 다운로드 시작
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            console.log(file, '파일')
            // const url = `/file/download/enter/${this.item.id}/${file}/${file}?attatch=false`;
            let url = wiz.url('download?id=' + this.item.id + "&title=" + files)
            // <a> 태그를 생성하여 다운로드
            const link = document.createElement('a');
            link.href = url;
            link.download = file;  // 파일 이름 지정 (선택사항)

            // 링크를 문서에 추가하고 클릭하여 다운로드 시작
            document.body.appendChild(link);
            link.click();

            // 링크를 문서에서 제거
            document.body.removeChild(link);
        }
    }
    // public async download(item, type) {
    //     if (!item[type] && !item[type + "x"]) return;
    //     if (await this.service.auth.allow(false)) {
    //         await this.alert("로그인 후 이용해주세요.");
    //         return 0;
    //     }
    //     type = item[type] ? type : type + "x";
    //     let download = wiz.url('download?id=' + item.id + "&title=" + item.title + "&type=" + type)
    //     window.open(download, '_blank');
    // }

    public async fileUpload(e) {
        console.log(e.addedFiles, '이벤트')
        for (let i = 0; i < e.addedFiles.length; i++) {
            let file = e.addedFiles[i]
            this.filename = file.name;
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.item.files.push(file.filepath);
            console.log(this.item.files, '아이템 파일스')
        }
        await this.service.render();
    }
    // public async removeFile(i) {
    //     this.item.files.splice(i, 1)
    // }
    // public async fileUpload(e) {
    //     console.log(e.addedFiles, '이벤트');
    //     for (let i = 0; i < e.addedFiles.length; i++) {
    //         let file = e.addedFiles[i]
    //         this.filename = file.name;
    //         if (!file.filepath) file.filepath = file.name;

    //         // 파일 크기 확인 (100MB 이상인 파일만 청크로 업로드)
    //         if (file.size > 100 * 1024 * 1024) {  // 100MB = 100 * 1024 * 1024 Bytes
    //             console.log('파일이 100MB 이상이므로 청크로 나눕니다.');

    //             // 파일을 청크로 나누는 로직
    //             await this.uploadFileInChunks(file);
    //         } else {
    //             this.fd.append('file[]', file);  // 작은 파일은 그대로 업로드
    //         }

    //         this.uploadedFiles.push(file.name);
    //         console.log(this.uploadedFiles, '업로드된 파일 리스트');
    //     }

    //     await this.service.render();
    // }

    // 파일을 청크로 나누어 업로드하는 함수
    public async uploadFileInChunks(file) {
        const chunkSize = 10 * 1024 * 1024;  // 청크 크기 10MB
        const totalChunks = Math.ceil(file.size / chunkSize);  // 전체 청크 수

        // 각 청크를 순차적으로 나누어 업로드
        for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min((i + 1) * chunkSize, file.size);
            const chunk = file.slice(start, end);  // 파일의 청크를 잘라냄

            // 청크를 폼 데이터에 추가
            this.fd.append('file[]', chunk, `${file.name}.part${i + 1}`);
            this.fd.append('partNumber', i + 1);
            this.fd.append('totalParts', totalChunks);
            this.fd.append('filename', file.name);

            console.log(`청크 ${i + 1} 준비 완료: ${start} ~ ${end}`);
        }
    }



    public async removeFile(index: number, isUploaded: boolean) {
        if (isUploaded) {
            this.uploadedFiles.splice(index, 1);
        } else {
            this.item.files.splice(index, 1);
        }
    }

    public async preview(item) {
        console.log(item, '아이템')
        if (!item.endsWith('.pdf')) return;
        let download = wiz.url('pdf?id=' + this.item.id + "&title=" + item)
        console.log(download)
        let res = await fetch(download, {
            method: "post",
        });

        const blob = await res.blob();
        const a = document.createElement('a');
        a.style.display = 'none';
        const download_url = window.URL.createObjectURL(blob);
        a.href = download_url;
        a.target = "_blank";

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(download_url);
    }


    public onRemove(event) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }
    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        this.router.navigateByUrl('/admin/report')
    }
}

export default PortalMicrobeadminAdminReportDetailComponent;